import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Loading, Separator, convertFirebaseTimestampToString } from "@moreirapontocom/npmhelpers";
import { listFeed } from "../../services/feed.service";
// import { listExplore } from "../../services/explore.service";
import { SkeletonFeed } from "../../components/Skeletons/Skeletons.component";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import "./Feed.scss";

dayjs.extend(relativeTime);

const Feed = (props: any) => {
  useEffect(() => {
    _listFeed();
    // _getExplore();
  }, []);

  const [lastVisibleDocId, setLastVisibleDocId] = useState(null as any);

  const [loadingFeed, setLoadingFeed] = useState(false);
  const [feed, setFeed] = useState([] as any);
  const _listFeed = async () => {
    setLoadingFeed(true);
    const response: any = await listFeed().then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      setFeed(response.feed);
      if (response.feed.length > 0) {
        setLastVisibleDocId(response.feed[response.feed.length-1].id);
      }
      setLoadingFeed(false);
      return;
    }

    console.log("Error loading feed", response);
    setLoadingFeed(false);
  };

  const [loadingMoreFeed, setLoadingMoreFeed] = useState(false);
  const _loadMoreFeed = async () => {
    setLoadingMoreFeed(true);
    const response: any = await listFeed(lastVisibleDocId).then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      setFeed([...feed, ...response.feed]);
      if (response.feed.length > 0) {
        setLastVisibleDocId(response.feed[response.feed.length-1].id);
      }

      setLoadingMoreFeed(false);
      return;
    }

    console.log("Error loading feed", response);
    setLoadingMoreFeed(false);
  }

  // const [loadingExplore, setLoadingExplore] = useState(false);
  // const [explore, setExplore] = useState([] as any);
  // const _getExplore = async () => {
  //   setLoadingExplore(true);
  //   const response: any = await listExplore().then((res: any) => res).catch((err: any) => err);
  //   if (response.message === "OK") {
  //     setExplore(response.explore);
  //     setLoadingExplore(false);
  //     return;
  //   }

  //   console.log("Error loading explore:", response);
  //   setLoadingExplore(false);
  // };

  // const _openExploreItem = (explore: any) => {
  //   window.open(explore.url, "_blank");
  // };

  return <>
    <div className="Feed container bg-white p-4 shadow-sm rounded-3">
      <small className="text-muted"><i className="fas fa-history me-2"></i> Recent Updates</small>

      <Separator size={30} />

      {loadingFeed && <SkeletonFeed />}

      {!loadingFeed && feed.length > 0 && <>
        <div className="row timeline">
          {feed.map((feed: any) => {
            return <div
              key={`Feed-item-${feed.id}`} className="col-12 mb-3">

                {feed.type === "welcome_to_team" && <>
                  <i className="fas fa-user-plus me-2 icon"></i>
                  Welcome to <Link to={`/teams/${feed.team.id}`}>{feed.team.name}</Link> team <span className="text-muted">- {dayjs(convertFirebaseTimestampToString(feed.createdAt)).fromNow()}</span>
                </>}

                {feed.type === "joined_via_link" && <>
                  <i className="fas fa-user-plus me-2 icon"></i>
                  <strong>{feed.author.displayName}</strong> joined <Link to={`/teams/${feed.team.id}`}>{feed.team.name}</Link> via link <span className="text-muted">- {dayjs(convertFirebaseTimestampToString(feed.createdAt)).fromNow()}</span>
                </>}

                {feed.type === "member_joined" && <>
                  <i className="fas fa-user-plus me-2 icon"></i>
                  <strong>{feed.author.displayName}</strong> joined <Link to={`/teams/${feed.team.id}`}>{feed.team.name}</Link> <span className="text-muted">- {dayjs(convertFirebaseTimestampToString(feed.createdAt)).fromNow()}</span>
                </>}

                {feed.type === "mentioned_in_comment" && <>
                  <i className="fas fa-comment me-2 icon"></i>
                  <strong>{feed.author.displayName}</strong> mentioned you on <Link to={`/teams/${feed.team.id}/${feed.bookmarkId}`}>this thread</Link> in <Link to={`/teams/${feed.team.id}`}>{feed.team.name}</Link> <span className="text-muted">- {dayjs(convertFirebaseTimestampToString(feed.createdAt)).fromNow()}</span>
                </>}

                {feed.type === "new_bookmark" && <>
                  <i className="fas fa-bookmark me-2 icon"></i>
                  <strong>{feed.author.displayName}</strong> added a <Link to={`/teams/${feed.team.id}/${feed.bookmarkId}`}>new bookmark</Link> in <Link to={`/teams/${feed.team.id}`}>{feed.team.name}</Link> <span className="text-muted">- {dayjs(convertFirebaseTimestampToString(feed.createdAt)).fromNow()}</span>
                </>}

                {feed.type === "new_bookmark_note" && <>
                  <i className="fas fa-comment-alt me-2 icon"></i>
                  <strong>{feed.author.displayName}</strong> added a <Link to={`/teams/${feed.team.id}/${feed.bookmarkId}`}>new note</Link> in <Link to={`/teams/${feed.team.id}`}>{feed.team.name}</Link> <span className="text-muted">- {dayjs(convertFirebaseTimestampToString(feed.createdAt)).fromNow()}</span>
                </>}

                {feed.type === "new_tag_created" && <>
                  <i className="fas fa-bookmark me-2 icon"></i>
                  <strong>{feed.author.displayName}</strong> added <div className="badge bg-secondary text-white ms-1 me-1">{feed.tagName}</div> to <Link to={`/teams/${feed.team.id}/${feed.bookmarkId}`}>this bookmark</Link> in <Link to={`/teams/${feed.team.id}`}>{feed.team.name}</Link> <span className="text-muted">- {dayjs(convertFirebaseTimestampToString(feed.createdAt)).fromNow()}</span>
                </>}

                {feed.type === "team_update" && <>
                  <i className="fas fa-list me-2 icon"></i>
                  <strong>{feed.author.displayName}</strong> renamed team "{feed.aux1}" <i className="fas fa-arrow-right ms-1 me-1"></i> "<Link to={`/teams/${feed.team.id}`}>{feed.aux2}</Link>" <span className="text-muted">- {dayjs(convertFirebaseTimestampToString(feed.createdAt)).fromNow()}</span>
                </>}

                {feed.type === "team_create" && <>
                  <i className="fas fa-plus me-2 icon"></i>
                  <strong>{feed.author.displayName}</strong> created team "<Link to={`/teams/${feed.team.id}`}>{feed.team.name}</Link>" <span className="text-muted">- {dayjs(convertFirebaseTimestampToString(feed.createdAt)).fromNow()}</span>
                </>}

            </div>
          })}
        </div>

        {/* Load More section */}

        {loadingMoreFeed && <SkeletonFeed />}

        {lastVisibleDocId && <>
          <Separator size={40} />
          <div className="text-center">
            <button
              onClick={() => _loadMoreFeed()}
              disabled={loadingFeed || loadingMoreFeed}
              className="btn btn-link btn-sm no-underscore">
                <Loading loading={loadingMoreFeed} parent="inline" color="text-primary" />
                {!loadingMoreFeed && <i className="fas fa-sync me-2"></i>} Load more
            </button>
          </div>
        </>}

      </>}

      {/*
      <Separator size={50} />

      <small className="text-muted"><i className="fas fa-globe-americas me-2"></i> Explore new curated Websites and Tools - <i>Powered by AI</i></small>

      <Separator size={30} />

      <Loading loading={loadingExplore} />

      {!loadingExplore && explore.length > 0 && <>
        <div className="row">

          {explore.map((explore: any, exploreIndex: number) => {
            return <div
              key={`Explore-${exploreIndex}`}
              onClick={() => _openExploreItem(explore)}
              className="col-3 mb-3 cursor-pointer">
                <div className="card shadow-sm">
                  <div className="card-body">

                    <div className="row">
                      <div className="col-3">
                        <img
                            src="/favicon.ico"
                            alt={explore.title}
                            className="img-fluid favicon" />
                      </div>
                      <div className="col">
                        <strong>{explore.title}</strong>

                        <Separator size={2} />

                        <div className="badge">{explore.tag}</div>
                      </div>
                    </div>

                    <Separator size={10} />

                    <small className="text-muted">{explore.description}</small>
                  </div>
                </div>
            </div>
          })}

        </div>
      </>}
      */}

    </div>
  </>
};

export default Feed;
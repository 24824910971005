import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AlertContext, Loading, Separator, truncateString } from "@moreirapontocom/npmhelpers";
import { createTeam, listTeams } from "../../services/teams.service";
import { SkeletonTeams } from "../../components/Skeletons/Skeletons.component";
import { DataContext } from "../../context/Data.context";
import { acceptInvitation } from "../../services/members.service";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const Teams = (props: any) => {
  const { data, setData } = useContext(DataContext);
  const { setAlert } = useContext(AlertContext);
  const navigate: any = useNavigate();

  useEffect(() => {
    _listTeams();
  }, []);

  const [loadingTeams, setLoadingTeams] = useState(false);
  const [teams, setTeams] = useState([] as any);
  const [pendingTeams, setPendingTeams] = useState([] as any);
  const _listTeams = async () => {
    setLoadingTeams(true);
    const response: any = await listTeams().then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      setTeams(response.teams.filter((team: any) => team.status === "active"));
      setPendingTeams(response.teams.filter((team: any) => team.status === "invited"));
      setLoadingTeams(false);
      return;
    }

    console.log("Error loading teams", response);
    setLoadingTeams(false);
  };

  const [loadingCreateTeam, setLoadingCreateTeam] = useState(false);
  const _createTeam = async () => {
    setLoadingCreateTeam(true);
    const response: any = await createTeam().then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      setLoadingCreateTeam(false);
      navigate(`/teams/welcome/${response.team.id}`);
      return;
    }

    console.log("Error creating team:", response);
    setLoadingCreateTeam(false);
  };

  const selectTeam = (team: any) => {
    setData({ ...data, currentTeam: team });
    navigate(`/teams/${team.id}`);
  };

  const [loadingAcceptInvitation, setLoadingAcceptInvitation] = useState(false);
  const [loadingAcceptInvitationTeamId, setLoadingAcceptInvitationTeamId] = useState(null as any);
  const _acceptInvitation = async (team: any) => {
    setLoadingAcceptInvitation(true);
    setLoadingAcceptInvitationTeamId(team.id);

    const response: any = await acceptInvitation(team.teamMemberId).then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      setPendingTeams(pendingTeams.filter((t: any) => t.id !== team.id));
      team.status = "active";
      team.membersCount = team.membersCount + 1;
      teams.push(team);
      setAlert({type: "success", message: `Invitation accepted<br />You're now a member of ${truncateString(team.name || "", 38, true)}!`});
      setLoadingAcceptInvitation(false);
      setLoadingAcceptInvitationTeamId(null);
      return;
    }

    setAlert({type: "danger", message: "Error accepting invitation"});
    console.log("Error accepting invitation:", response);
    setLoadingAcceptInvitation(false);
    setLoadingAcceptInvitationTeamId(null);
  };

  return <>
    <div className="Feed container bg-white p-4 shadow-sm rounded-3">
      <small className="text-muted"><i className="fas fa-stream me-2"></i> My Teams</small>

      <Separator size={30} />

      {loadingTeams && <SkeletonTeams />}

      {!loadingTeams && <>

        {pendingTeams && pendingTeams.length > 0 && <>
          <div className="alert alert-warning">
            <strong>Pending Invitations ({pendingTeams.length})</strong>

            <Separator size={15} />

            <small>
              Accept the invitations to join the teams below.
            </small>

            <Separator size={15} />

            <ul className="list-group list-group-flush bg-transparent">
              {pendingTeams.map((team: any) => {
                return <li key={`Teams-pending-team-${team.id}`} className="list-group-item bg-transparent ps-0">
                  <div className="row align-items-center">
                    <div className="col">
                      &raquo; {truncateString(team.name || "", 38, true)}
                    </div>
                    <div className="col-6 text-end">
                      <button
                        className="btn btn-primary btn-sm"
                        disabled={loadingAcceptInvitation && loadingAcceptInvitationTeamId === team.id}
                        onClick={() => _acceptInvitation(team)}>
                          <Loading loading={loadingAcceptInvitation && loadingAcceptInvitationTeamId === team.id} parent="inline" color="text-white" />
                          {!loadingAcceptInvitation && <i className="fas fa-check me-2"></i>} Accept "{team.name}"
                      </button>
                    </div>
                  </div>
                </li>
              })}
            </ul>
          </div>

          <Separator size={30} />
        </>}

        <div className="row">

          {teams && teams.length > 0 && teams.map((team: any) => {
            return <div key={`Teams-team-${team.id}`} className="col-4 mb-4">
              <div className="card shadow-sm cursor-pointer">
                <div className="card-body" onClick={() => selectTeam(team)}>
                  <strong>{truncateString(team.name || "", 38, true)}</strong>
  
                  <Separator size={15} />

                  <small className="text-muted">
                    <div className="row">
                      <div className="col">
                        {(!team.membersCount || team.membersCount === 1) && <>
                          <i className="fas fa-lock me-2"></i> Private <strong>(only you)</strong>
                        </>}
                        {team.membersCount > 1 && <>
                          <i className="fas fa-users me-2"></i> {team.membersCount || 0} Members
                        </>}
                      </div>
                      <div className="col text-end">
                        <i className="fas fa-list me-2"></i> {team.bookmarksCount || 0} Items
                      </div>
                    </div>
                  </small>

                </div>
              </div>
            </div>
          })}

          <div key="Teams-create-team" className="col-12">
            <div className={`card shadow-sm bg-primary ${loadingCreateTeam ? "disabled" : ""}`}>
              <div className="card-body text-center d-flex align-items-center justify-content-center">

                <div
                  className="cursor-pointer text-white"
                  onClick={() => _createTeam()}>
                    {loadingCreateTeam && <>
                      <Loading loading={true} parent="inline" color="text-white" /> Creating your team...
                    </>}

                    {!loadingCreateTeam && <>
                      <i className="fas fa-plus-circle me-2"></i>
                      Create Team
                    </>}
                </div>

              </div>
            </div>
          </div>
        </div>
      </>}

    </div>
  </>
};

export default Teams;
import { Separator } from "@moreirapontocom/npmhelpers";
import { Link } from "react-router-dom";

const NewAccountSuccess = () => {
  return <div className="NewAccountSuccess container bg-white p-4 shadow-sm rounded-3 mt-5">
    <div className="text-center">

      <strong>Welcome to URL Collab!</strong>
      <Separator size={10} />

      It's time to never miss a link again.

      <Separator size={60} />
      <img src="/welcome-to-urlcollab.svg" alt="" className="img-fluid" style={{ maxWidth: "250px" }} />

      <Separator size={50} />
      <Link to="/login" className="btn btn-primary">Go to login &rarr;</Link>
    </div>

    <Separator size={30} />
  </div>;
};

export default NewAccountSuccess;
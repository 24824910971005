import { Separator } from "@moreirapontocom/npmhelpers";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const NewTeamSuccess = () => {
  const params: any = useParams();
  const [teamId, setTeamId] = useState(null);

  useEffect(() => {
    if (params.teamId) {
      setTeamId(params.teamId);
    }
  }, [params.teamId]);

  return <div className="NewTeamSuccess container bg-white p-4 shadow-sm rounded-3">
    <div className="text-center">

      <strong>Your new team has been created!</strong>
      <Separator size={10} />

      It's time to never miss a link again.

      <Separator size={60} />
      <img src="/welcome-to-team.svg" alt="" className="img-fluid" style={{ maxWidth: "350px" }} />

      {teamId && <>
        <Separator size={50} />
        <Link to={`/teams/${teamId}`} className="btn btn-primary">Go to the team &rarr;</Link>
      </>}
    </div>

    <Separator size={30} />
  </div>;
};

export default NewTeamSuccess;